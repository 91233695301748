import React, { FC } from "react";
import * as styles from "../css/category-map-content.module.css";

type CategoryMapProps={
  title: string,
  slug: string,
  category: string
}

export const CategoryMapContent: FC<CategoryMapProps> = ({ title, slug, category })=>{
  return (
    <div className={styles.contentBox}>
      <a href={ `${category}/${slug}` }>{ title }</a>
    </div>
  )
}