import React, { FC } from 'react';
import * as styles from "../css/category-container.module.css";

type Props={
  header: string,
  category: string
}

export const CategoryContainer: FC<Props>=({ children, header, category })=>{
  return (
    <div className={styles.container}>
      <h2 className={styles.titleBox}><a href={ category }>{ header }</a></h2>
      { children }
    </div>
  )
}