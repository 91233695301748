import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from '../css/index.module.css'

import { CategoryContainer } from "../components/category-container"
import { JsxElement } from "typescript"
import { CategoryMapContent } from "../components/category-map-content"
import { SearchBar } from "../components/search-bar"
import { Button } from "../components/button"
import { ImgTextContainer } from "../components/image-text-container"
import { Heading1 } from "../components/heading-1";
import { ContainerCenter } from "../components/container-center";

// <Container>
//  <Child/>
//  <Child/>
// <Container/>

class ContentMap{
  data: Record<string, React.ReactElement[]>={}
  maxContentNumber: number;

  constructor(maxContentNumber: number){
    this.maxContentNumber=maxContentNumber;
  }

  add(category: string, elm: React.ReactElement){
    if(!(category in this.data)){
      this.data[category]=[];
    }

    if(this.data[category].length < this.maxContentNumber) this.data[category].push(elm);
  }

  extract(){
    const result: React.ReactElement[]=[];

    for(const category in this.data){
      result.push(<CategoryContainer category={ category } header = { category }>{ this.data[category] }</CategoryContainer>)
    }

    return result;
  }
}


const BlogIndex: React.FC<PageProps<GatsbyTypes.BlogIndexQuery>> = ({ data, location, pageContext }) => {
  const siteTitle = data.site?.siteMetadata?.title || `nodata`;
  const contentMap = new ContentMap(3);

  data.allServicePageJson.nodes.forEach(node=>{
    if(node.category===undefined || node.title===undefined || node.slug===undefined) throw new Error('invalide content data');
    contentMap.add(node.category, <CategoryMapContent title={ node.title } slug={ node.slug } category={ node.category } />);
  });

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} lang="ja" description={data.site?.siteMetadata?.description} />

      <ContainerCenter>
        <div className={styles.serchContainer}>
          <div className={styles.serchDiscription}>
            気になる商品や、サービスを検索！
          </div>
          <SearchBar></SearchBar>
        </div>
      </ContainerCenter>
      {/* <div className={styles.hero}>
        <div className={styles.heroImage}>
          <StaticImage src="../images/home/hero.jpg" alt="hero image"></StaticImage>
        </div>
        <div className={styles.heroText}>
          始めよう。0円寄付。
        </div>
      </div> */}
      
      <ContainerCenter>
        <div className={styles.containerLeft}>
          <Heading1 text="SERVICES"/>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.containerSubtitle}>
            KIFU PLANETでお買い物すると0円で寄付できます。
          </div>
          <div className={styles.containerText}>
            KUFU PLANET はアフィリエイト収益を寄付するサービスです。<br></br>
            もうレジ横の募金箱に小銭を入れる必要はありません。
          </div>
          <Button link="/about" text ="READ MORE" />
        </div>
      </ContainerCenter>

      <Heading1 text="CATEGORIES"/>
      <ContainerCenter>
        <div className={styles.containerCategory}>
          { contentMap.extract() }
        </div>
      </ContainerCenter>
    </Layout>
  )
}

export default BlogIndex
export const pageQuery = graphql`
query BlogIndex {
  site {
    siteMetadata {
      title
      description
    }
  }
  allServicePageJson {
    nodes {
      category
      slug
      title
    }
  }
}
`